import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="emotional-design" mdxType="Subpage">
      <h3>{`Emotional Design`}</h3>
      <p>{`Let's start a new text, Aarron Walter's `}<em parentName="p">{`Designing for Emotion`}</em>{`. What does it mean to "design for emotion?" How can we make our interfaces not just functional and efficient, but also appealing to people?`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 1 from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/designing-for-emotion/9780133052954/"
          }}>{`Walter`}</a>{`.`}</p>
      </Callout>
      <h4>{`Questions:`}</h4>
      <ul>
        <li parentName="ul">{`What are the components of user experience Walter bases on Maslow's pyramid?`}</li>
        <li parentName="ul">{`What ways did sites like Wufoo and Betabrand distinguish themselves in relation to Walter's pyramid?`}</li>
        <li parentName="ul">{`What is the relationship between emotions and memory?`}</li>
        <li parentName="ul">{`What is emotional design?`}</li>
      </ul>
    </Subpage>
    <Subpage slug="designing-for-humans" mdxType="Subpage">
      <h3>{`Designing for Humans`}</h3>
      <p>{`Next we continue thinking about emotional design specifically as geared towards real human beings. Genius.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 2 from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/designing-for-emotion/9780133052954/"
          }}>{`Walter`}</a>{`.`}</p>
      </Callout>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      